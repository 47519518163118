import classNames from 'classnames';
import styles from '../../../../styles/pages/home.module.scss';
import Image from 'next/image';

import { RightOutlined, LeftOutlined } from '@ant-design/icons';

type BtnCarousel = {
  moveSlide: () => void;
  direction: string;
};

export default function BtnCarousel({ moveSlide, direction }: BtnCarousel) {
  return (
    <button
      onClick={moveSlide}
      className={classNames(
        styles.btnSlide,
        direction === 'next' ? styles.next : styles.prev
      )}
    >
      {direction === 'next' ? (
        <RightOutlined style={{ fontSize: '20px', color: 'black' }} />
      ) : (
        <LeftOutlined size={20} style={{ fontSize: '20px', color: 'black' }} />
      )}
    </button>
  );
}
