import Style from '../../styles/pages/region-pacifico/region-pacifico.module.scss';
import Styles from '../../styles/pages/home.module.scss';
import { useTranslations } from 'next-intl';
import { useRef, useState } from 'react';
import Link from 'next/link';
import classNames from 'classnames';

type Props = {
  title: string;
};
type cards = {
  icon: string;
  title: string;
  text: string;
  url: string;
  id: number;
};

export default function Cards({ title }: Props) {
  const inputElement = useRef<HTMLDivElement>(null);
  const t = useTranslations('regionPacifico');

  const list_card_meet_other_towns: cards[] = [
    {
      icon: '/images/regionPacifica/Cards/Choco.jpg',
      title: 'Chocó',
      text: t('cardChoco'),
      url: '/departamento/choco',
      id: 0,
    },
    {
      icon: '/images/regionPacifica/Cards/valleCauca.jpg',
      title: 'Valle del Cauca',
      text: t('cardValle'),
      url: '/departamento/valle-del-cauca',
      id: 1,
    },
    {
      icon: '/images/regionPacifica/Cards/Cauca.svg',
      title: 'Cauca',
      text: t('cardCauca'),
      url: '/departamento/cauca',
      id: 2,
    },
    {
      icon: '/images/regionPacifica/Cards/Narino.svg',
      title: 'Nariño',
      text: t('cardNarino'),
      url: '/departamento/narino',
      id: 3,
    },
  ];
  const [numberState, setNumberState] = useState(1);
  let lenghtList = list_card_meet_other_towns.length;

  const scrollLeft = (number: number) => {
    if (inputElement !== null) {
      // window.scroll(0,inputElement.current.offsetLeft)
      if (number === 1 && numberState < lenghtList) {
        inputElement.current.scrollLeft += 380;
        setNumberState(numberState + 1);
      } else if (number === 2 && numberState > 1) {
        inputElement.current.scrollLeft -= 380;
        setNumberState(numberState - 1);
      }
    }
  };
  return (
    <>
      <h2 className={Style.h2_up_cards}>{title}</h2>

      <div className={Style.cards_meet_other_towns} ref={inputElement}>
        {list_card_meet_other_towns.map((item) => {
          return (
            <Link
              key={item.id}
              href={{
                pathname: item.url,
                query: null,
              }}
            >
              <div
                className={Style.card_meet_other_towns}
                style={{ cursor: 'pointer' }}
              >
                <div className={Style.content_icon_other_towns}>
                  <img
                    src={item.icon}
                    alt="img"
                    className={Style.img_top_other_towns}
                  />
                </div>
                <div className={Style.content_card_other_towns}>
                  <div>{item.title}</div>
                  <div>
                    <p>{item.text}</p>
                    <div>
                      <Link
                        href={{
                          pathname: item.url,
                          query: null,
                        }}
                      >
                        <div>
                          <p>{t('textConocer')}</p>
                          <img src="/icons/chevronright.svg" alt="icon" />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
      <div className={Styles.control_buttons_slider}>
        <div
          className={classNames(Styles.button_slider, Styles.rotate)}
          onClick={() => scrollLeft(2)}
        >
          <img src="/icons/chevronright.svg" alt="arrow" id="btnScrollLeft" />
        </div>
        <p>{numberState + ' / ' + lenghtList}</p>
        <div className={Styles.button_slider} onClick={() => scrollLeft(1)}>
          <img src="/icons/chevronright.svg" alt="arrow" id="btnScrollLeft" />
        </div>
      </div>
    </>
  );
}

export function CardsHome({ title }: Props) {
  const t = useTranslations('regionPacifico');
  const list_card_meet_other_towns: cards[] = [
    {
      icon: '/images/regionPacifica/Cards/Choco.jpg',
      title: 'Chocó',
      text: t('cardChoco'),
      url: '/departamento/choco',
      id: 0,
    },
    {
      icon: '/images/regionPacifica/Cards/valleCauca.jpg',
      title: 'Valle del Cauca',
      text: t('cardValle'),
      url: '/departamento/valle-del-cauca',
      id: 1,
    },
    {
      icon: '/images/regionPacifica/Cards/Cauca.svg',
      title: 'Cauca',
      text: t('cardCauca'),
      url: '/departamento/cauca',
      id: 2,
    },
    {
      icon: '/images/regionPacifica/Cards/Narino.svg',
      title: 'Nariño',
      text: t('cardNarino'),
      url: '/departamento/narino',
      id: 3,
    },
  ];

  const [numberState, setNumberState] = useState(1);
  const inputElement = useRef<HTMLDivElement>(null);
  let lenghtList = list_card_meet_other_towns.length;

  const scrollLeft = (number: number) => {
    if (inputElement !== null) {
      // window.scroll(0,inputElement.current.offsetLeft)
      if (number === 1 && numberState < lenghtList) {
        inputElement.current.scrollLeft += 320;
        setNumberState(numberState + 1);
      } else if (number === 2 && numberState > 1) {
        inputElement.current.scrollLeft -= 320;
        setNumberState(numberState - 1);
      }
    }
  };

  return (
    <>
      {title && <h2 className={Style.h2_up_cards}>{title}</h2>}
      <div className={Styles.containerCardsDeparments}>
        <div className={Styles.cards_meet_other_towns} ref={inputElement}>
          {list_card_meet_other_towns.map((item) => {
            return (
              <Link
                href={{
                  pathname: item.url,
                  query: null,
                }}
                key={item.id}
              >
                <div className={Styles.card_meet_other_towns}>
                  <div className={Styles.content_icon_other_towns}>
                    <img
                      src={item.icon}
                      alt="img"
                      className={Styles.img_top_other_towns}
                    />
                  </div>
                  <div className={Styles.content_card_other_towns}>
                    <div>{item.title}</div>
                    <div>
                      <p>{item.text}</p>
                      <div>
                        <Link
                          href={{
                            pathname: item.url,
                            query: null,
                          }}
                        >
                          <div>
                            <p>{t('textConocer')}</p>
                            <img src="/icons/chevronright.svg" alt="icon" />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
        <div className={Styles.control_buttons_slider}>
          <div
            className={classNames(Styles.button_slider, Styles.rotate)}
            onClick={() => scrollLeft(2)}
          >
            <img src="/icons/chevronright.svg" alt="arrow" id="btnScrollLeft" />
          </div>
          <p>{numberState + ' / ' + lenghtList}</p>
          <div className={Styles.button_slider} onClick={() => scrollLeft(1)}>
            <img src="/icons/chevronright.svg" alt="arrow" id="btnScrollLeft" />
          </div>
        </div>
      </div>
    </>
  );
}
