import styles from '../../../../styles/pages/home.module.scss';
import Icon from '../../../base/icon';
import { useTranslations } from 'next-intl';
import Link from 'next/link';

type ExperienceCardType = {
  logo: string;
  title: string;
  content: string;
  link: string;
  altText: string;
  image: string;
};

export default function ExperienceCard(props: ExperienceCardType) {
  const t = useTranslations('Home');

  return (
    <Link
      href={{
        query: null,
        pathname: props.link,
      }}
      role="link"
      aria-label={'experiencia - ' + props.title}
    >
      <div
        className={styles.thumbnail}
        style={{ backgroundImage: `url('${props.image}')`, cursor: 'pointer' }}
      >
        <div>
          <img
            src={`images/experiencia-pacifico-logo/${props.logo}`}
            alt={props.altText}
          />
          <div className={styles.infoContainer}>
            <h3>{props.title}</h3>
            <p>{props.content}</p>
            <Link
              href={{
                query: null,
                pathname: props.link,
              }}
              role="link"
              aria-label={'experiencia - ' + props.title}
            >
              <a
                className={styles.anchorInfo}
                role="link"
                aria-label={'experiencia - ' + props.title}
              >
                {t('textExplore')}{' '}
                <Icon icon="arrow" size={12} color="#233E7F" />
              </a>
            </Link>
          </div>
        </div>
      </div>
    </Link>
  );
}
