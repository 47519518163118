import { format } from 'date-fns';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { es, en } from 'react-date-range/dist/locale';
import Image from 'next/image';
import {
  fixDate,
  getDepartmentName,
  getImageURL,
  getMunicipalityName,
  splitField,
} from '../../../../shared/utils';
import styles from '../../../../styles/pages/negocios/index.module.scss';
import { GeneralConfigContext } from '../../../general-config-provider';

export default function EventCardHomePST({ event }) {
  const [locale, setLocale] = useState('es');
  const router = useRouter();
  const { generalConfig } = useContext(GeneralConfigContext);
  const initialDate = fixDate(event.fecha_inicial);

  useEffect(() => {
    if (router.locale) {
      setLocale(router?.locale);
    }
  }, [router?.locale]);
  return (
    <>
      <div className={styles.thumbnail}>
        <a href={"/eventos/"+event?.id_evento}>
          <div className={styles.contentImg}>
            <div className={styles.header}>
              <div className={styles.date}>
                <p>{initialDate.getDate()}</p>
                <p>
                  {format(initialDate, 'MMMM', {
                    locale: locale === 'en' ? en : es,
                  })}
                </p>
              </div>

            </div>
            <div className={styles.imageContainer}>
                {event?.imagen_evento.length > 0 && (
                  <>
                    <img
                      alt={splitField(event?.nombre, locale)}
                      src={getImageURL(event?.imagen_evento[event?.imagen_evento.length - 1]?.ImagenURL)}
                    />
                  </>
                )}
              </div>
          </div>
          <div className={styles.content}>
            <article>
              <h3>{splitField(event?.nombre, locale)}</h3>
              <p>
                <strong>Evento:</strong> Regional
              </p>
            </article>
            <article>
              <h4>LUGAR</h4>
              <p className={styles.location}>
                Departamento:{' '}
                {event?.departamentos_evento?.map((ev) => {
                  return (
                    <a>
                      {getDepartmentName(
                        ev.id_departamento,
                        generalConfig?.departamento
                      )}
                    </a>
                  );
                })}{' '}
              </p>
              <p>
                Municipios:{' '}
                <a href="#">
                  {getMunicipalityName(
                    event?.id_municipio,
                    generalConfig?.ciudad
                  )}
                </a>{' '}
              </p>
            </article>
          </div>
        </a>
      </div>
    </>
  );
}
