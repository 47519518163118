import styles from '../../../../styles/pages/home.module.scss';
import ExperienceCard from './experience-card';
import { useTranslations } from 'next-intl';

export default function ExperienceHomeSection() {
  const t = useTranslations('Home');

  return (
    <section className={styles.containerSection}>
      <div className={styles.container_homeWelcome}>
        <p>{t('parrafoHome')}</p>
        <h2>{t('subtitleHome')}</h2>
      </div>

      <h2>{t('title')}</h2>
      <p>{t('subTitle')}</p>
      <div className={styles.grid}>
        <ExperienceCard
          link={'/productos/paraiso-litoral'}
          logo={'blue-experience.svg'}
          title={t('cardLitoral')}
          content={t('descriptionLitoral')}
          altText={t('cardLitoral')}
          image={'images/experiencia-pacifico-logo/ParaisoLiteral.jpg'}
        />
        <ExperienceCard
          link={'/productos/travesia-natural'}
          logo={'green-experience.svg'}
          title={t('cardNatural')}
          content={t('descriptionNatural')}
          altText={t('cardNatural')}
          image={'images/experiencia-pacifico-logo/TravesiaNatural.png'}
        />
        <ExperienceCard
          link={'/productos/herencia-cultural'}
          logo={'red-experience.svg'}
          title={t('cardCultural')}
          altText={t('cardCultural')}
          content={t('descriptionCultural')}
          image={'images/experiencia-pacifico-logo/HerenciaCultural.png'}
        />
        <ExperienceCard
          link={'/productos/experencia-mistica'}
          logo={'yellow-experience.svg'}
          altText={t('cardMistica')}
          title={t('cardMistica')}
          content={t('descriptionMistica')}
          image={'images/experiencia-pacifico-logo/ExperienciaMistica.png'}
        />
      </div>
    </section>
  );
}

export async function getStaticProps({ locale }) {
  const response = await import(`../../../../lang/${locale}.json`);

  return {
    props: {
      lang: response.default,
    },
  };
}
