import classNames from 'classnames';
import { useEffect, useState } from 'react';
import styles from '../../../../styles/pages/home.module.scss';
import BtnCarousel from './btnCarousel';
import router from 'next/router';

type CarouselType = {
  autoPlay?: boolean;
};

export default function Carousel(props: CarouselType) {
  let images = [
    'banner-1.jpg',
    'banner-choco.webp',
    'banner-valle.webp',
    'banner-cauca.webp',
    'banner-narino.jpg',
  ];

  let imagesResponsive = [
    'banner-1m.jpg',
    'banner-chocom.webp',
    'banner-vallem.webp',
    'banner-caucam.webp',
    'banner-narinom.webp',
  ];

  const [slideIndex, setSlideIndex] = useState(0);
  const [displayedImages, setDisplayedImages] = useState(images);

  const nextSlide = () => {
    setSlideIndex((prevIndex) => (prevIndex + 1) % displayedImages.length);
  };

  const prevSlide = () => {
    setSlideIndex(
      (prevIndex) =>
        (prevIndex - 1 + displayedImages.length) % displayedImages.length
    );
  };

  const moveDot = (index: number) => {
    setSlideIndex(index);
  };

  const updateImageSet = () => {
    // Cambia el conjunto de imágenes según el ancho de la ventana
    if (window.innerWidth > 700) {
      setDisplayedImages(images);
    } else {
      setDisplayedImages(imagesResponsive);
    }
  };

  useEffect(() => {
    // Configura el tamaño inicial
    updateImageSet();

    // Agrega el listener para cambios de tamaño de ventana
    window.addEventListener('resize', updateImageSet);

    // Limpia el listener al desmontar
    return () => {
      window.removeEventListener('resize', updateImageSet);
    };
  }, []);

  useEffect(() => {
    if (props.autoPlay) {
      const interval = setInterval(nextSlide, 6000);
      return () => clearInterval(interval);
    }
  }, [slideIndex, props.autoPlay]);

  return (
    <div style={{ cursor: 'pointer' }} className={styles.containerSlider}>
      {displayedImages.map((obj, index) => {
        return (
          <div
            key={obj}
            onClick={() => router.push('/cop16')}
            className={classNames(
              styles.slide,
              slideIndex === index ? styles.activeAnim : ''
            )}
          >
            <img src={`images/cop16/${obj}`} alt="img carousel" />
          </div>
        );
      })}

      <BtnCarousel moveSlide={nextSlide} direction={'next'} />
      <BtnCarousel moveSlide={prevSlide} direction={'prev'} />

      <div className={styles.containerDots}>
        {displayedImages.map((_, index) => (
          <div
            key={index}
            onClick={() => moveDot(index)}
            className={classNames(
              styles.dot,
              slideIndex === index ? styles.active : ''
            )}
          ></div>
        ))}
      </div>
    </div>
  );
}
