import Styles from '../../../styles/pages/payment-methods-home.module.scss';
import classNames from 'classnames';
import Image from 'next/image';
import { useTranslations } from 'next-intl';

export default function PaymentMethodsHome() {
  const t = useTranslations('Home.PaymentMethodsHome');

  return (
    <section className={Styles.contents}>
      <div>
        <div className={Styles.section1}>
          <h2>{t('title')}</h2>
          <p>{t('text')}</p>
          <div>
            <Image
              src="/images/medios-de-pago.png"
              alt="Logo Mano"
              width={'390px'}
              height="95px"
            />
          </div>
        </div>
        <div className={Styles.section2}>
          <img
            src="/images/home-turista/HomeMediosPago.png"
            alt="Medios de Pago"
          />
        </div>
        <div style={{ position: 'absolute', right: '40px' }}>
          <button
            style={{ color: 'transparent' }}
            className={classNames(['btn', 'outline-primary'])}
            onClick={() => window.scroll({ top: 0, behavior: 'smooth' })}
          >
            <img src="/images/svg/icon-11.svg" alt="" />.
          </button>
        </div>
      </div>
    </section>
  );
}
