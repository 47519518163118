import { useEffect, useState } from 'react';
import { allowUbication } from '../shared/utils';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { apiRestGet, apiRestGetMaps } from '../services/request';
import { GOOGLE_MAPS_API_KEY } from '../shared/constants';

export type UbicationType = {
  latitude?: number;
  longitude?: number;
  message: string;
  ubication?: Ubication;
};

interface Ubication {
  formatted_address: string;
  place_id: string;
  address_components: Address[];
}

interface Address {
  long_name: string;
  short_name: string;
}

const GOOD_RESPONSE = 'Todo correcto.';

export const ubicationHook = () => {
  const [ubication, setUbication] = useState<UbicationType>({
    latitude: null,
    longitude: null,
    message: '',
    ubication: null,
  });

  const handleUbication = async () => {
    allowUbication(
      async (position: GeolocationPosition) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        const dato = await getDepartamentByLatitudeAndLongitude(
          latitude,
          longitude
        );
        setUbication({
          latitude,
          longitude,
          message: GOOD_RESPONSE,
          ubication: dato,
        });
      },
      () => {
        setUbication({
          message: 'No se puede recuperar tu ubicación.',
        });
      }
    );
  };

  const getDepartamentByLatitudeAndLongitude = async (
    latitude: number,
    longitude: number
  ) => {
    try {
      const response = await apiRestGetMaps(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}`
      );

      return response.results[7];
    } catch (error) {
      console.log(error);
      ('');
    }
  };

  return {
    ubication,
    handleUbication,
  };
};
